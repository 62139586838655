<template>
  <div>
    <v-app-bar
        id="home-app-bar"
        app
        color="white"
        flat
        height="70"
    >

      <router-link :to="`/${$i18n.locale}`">
        <base-img
            :src="require('@/assets/evaluate.svg')"
            contain
            max-width="120"
            width="100%"
        />
      </router-link>

      <v-spacer/>

      <div>
        <v-tabs
            class="hidden-sm-and-down"
            optional
        >
          <v-tab
              v-for="(name, i) in items"
              :key="i"
              :to="name.path"
              :exact="true"
              :ripple="false"
              active-class="text--primary"
              class="font-weight-bold"
              min-width="96"
              text
          >
            {{ name.title }}
          </v-tab>
          <v-tab
              active-class="text--primary"
              class="font-weight-bold"
              min-width="96"
              text
              href="https://tool.evaluate.se"
              target="_blank"
          >
            {{ $t('section.sign_in') }}
          </v-tab>
        </v-tabs>
      </div>

      <v-btn v-if="$i18n.locale === 'en'" icon @click="setLocale('sv')">
        <v-img max-width="24" :src="require('@/assets/swe.svg')">
        </v-img>
      </v-btn>

      <v-btn v-else-if="$i18n.locale === 'sv'" icon @click="setLocale('en')">
        <v-img max-width="24" :src="require('@/assets/gbr.svg')">
        </v-img>
      </v-btn>

      <v-app-bar-nav-icon
          class="hidden-md-and-up"
          @click="drawer = !drawer"
      />
    </v-app-bar>

    <home-drawer
        v-model="drawer"
        :items="items"
    />
  </div>
</template>

<script>
import sweFlag from '@/assets/swe.svg'

export default {
  name: 'HomeAppBar',

  components: {
    HomeDrawer: () => import('./Drawer'),
  },

  data: () => ({
    drawer: null,
    icons: {
      sweFlag,
    },
  }),
  computed: {
    items() {
      return [
        {
          path: `/${this.$i18n.locale}`,
          component: 'Home',
          title: this.$t('section.home'),
        },
        // {
        //   path: `/${this.$i18n.locale}/about`,
        //   component: 'About',
        //   title: this.$t('section.about'),
        // },
        {
          path: `/${this.$i18n.locale}/due-diligence`,
          component: 'Due Diligence',
          title: this.$t('section.due_diligence'),
        },
        {
          path: `/${this.$i18n.locale}/reporting`,
          component: 'Reporting',
          title: this.$t('section.reporting'),
        },
        {
          path: `/${this.$i18n.locale}/customer-stories`,
          component: 'CustomerStories',
          title: this.$t('section.customer_stories'),
        },
        {
          path: `/${this.$i18n.locale}/contact-us`,
          component: 'Contact',
          title: this.$t('section.contact_us'),
        },
      ];
    }
  },
  methods: {
    setLocale(locale) {
      this.$i18n.locale = locale;
      this.$router.push(`/${locale}`);
    }
  }
}
</script>

<style lang="sass">
#home-app-bar
  .v-tabs-slider
    max-width: 24px
    margin: 0 auto

  .v-tab
    &::before
      display: none
</style>
